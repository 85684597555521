








import Vue from 'vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { FadeTransition } from 'vue2-transitions';

export default Vue.extend({
  components: {
    FadeTransition,
  },
});
