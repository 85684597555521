






import Vue from 'vue';

export default Vue.extend({
  name: 'authorized-router-link',
  inheritAttrs: false,
  props: {
    name: String,
    icon: String,
    to: String,
  },
  data() {
    return {
      isVisible: true,
    };
  },
  beforeMount() {
    const [currentRoute] = this.$router.getRoutes()
      .filter((route: any) => !!this.to.match(route.regex));

    this.isVisible = this.$store.state.user.current.policies
      .filter((policy: any) => !!policy[currentRoute.meta.requiresPermission]).length > 0;
  },
});

