import {
  Action, Module, VuexModule,
} from 'vuex-module-decorators';
import { IPainelBffService } from '@/services/painel-bff.service';
import { Inject } from '@/di/utils';
import TYPES from '@/di/types';
import { RealtimeMarket } from '@/data/dto/realtime-market.dto';

@Module
export default class MarketModule extends VuexModule {
  @Inject(TYPES.PainelBffService)
  painelBffService!: IPainelBffService

  @Action
  getAllMarketsNames() {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService.getAllMarketsNames().finally(() => this.context.dispatch('handleLoading', false));
  }
}
