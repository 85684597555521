import {
  Action, Module, VuexModule,
} from 'vuex-module-decorators';
import { IPainelBffService } from '@/services/painel-bff.service';
import TYPES from '@/di/types';
import { Inject } from '@/di/utils';
import UploadImageService from '@/services/upload-image.service';
import { Program } from '@/data/entity/program.entity';
import { SignedUrlDto } from '@/data/dto/signed-url.dto';
import { uuid } from 'vue-uuid';

type ImageUploadPayload = {
  title: string;
  program: string;
  alternativeName: string;
  marketSelected: string;
  image: string;
  currentImageURL?: string;
}

type GetUploadImageSignedUrl = {
  fileName: string;
  contentType: string;
}

@Module
export default class ProgramModule extends VuexModule {
  @Inject(TYPES.PainelBffService)
  painelBffService!: IPainelBffService

  @Action
  getAllPrograms() {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService.getAllPrograms()
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  searchPoster(params: { searchTerm: string; currentPage: number; perPage: number }) {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService.searchPoster(params.searchTerm, params.currentPage, params.perPage)
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  getOneProgram(key: string) {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService.getOneProgram(key).finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  removeImage(key: string) {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService.removeImage(key).finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  async addImage(payload: ImageUploadPayload) {
    try {
      this.context.dispatch('handleLoading', true);
      const url = payload.currentImageURL && !payload.image
        ? payload.currentImageURL
        : await this.context.dispatch('uploadImage', payload);

      const program = new Program(
        payload.title,
        payload.program,
        url,
        payload.marketSelected,
        payload.alternativeName,
      );

      await this.painelBffService.addImage(program);

      this.context.dispatch('handleLoading', false);
    } catch (error) {
      this.context.dispatch('handleLoading', false);
      throw error;
    }
  }

  @Action
  uploadImage(payload: ImageUploadPayload) {
    const imageBase64 = payload.image.replace(/^data:image\/\w+;base64,/, '');
    const contentType = payload.image.split(';')[0].split(':')[1];
    const fileExtension = contentType.split('/')[1];
    const name = `${uuid.v4()}.${fileExtension}`;
    const imageData = Buffer.from(imageBase64, 'base64');

    return this.context.dispatch('getUploadImageSignedUrl', { fileName: name, contentType })
      .then((data: SignedUrlDto) => new UploadImageService()
        .uploadImage(data.signedUrl, contentType, imageData)
        .then(() => data.outputURL));
  }

  @Action
  getUploadImageSignedUrl(payload: GetUploadImageSignedUrl): Promise<SignedUrlDto> {
    return this.painelBffService
      .getUploadImageSignedUrl(payload.fileName, payload.contentType);
  }
}
