import {
  inject as __inject,
  LazyServiceIdentifer,
} from 'inversify';
import container from '@/di/container';

type ServiceIdentifierOrFunc = symbol | LazyServiceIdentifer;

type Constructor<T = any> =
  | {
  new (...args: any[]): T;
}
  | any;

export function isParameterDecorator(index: number): boolean {
  return index !== undefined ? typeof index === 'number' : false;
}

function injectParameterDecorator(
  target: Constructor,
  methodName: string,
  index: number,
  serviceIdentifier: ServiceIdentifierOrFunc,
): void {
  return __inject(serviceIdentifier)(target, methodName, index);
}

function injectPropertyDecorator(
  target: any,
  methodName: string,
  descriptor: PropertyDescriptor,
  serviceIdentifier: symbol,
) {
  if (descriptor) {
    // eslint-disable-next-line no-param-reassign
    descriptor.get = () => container.get(serviceIdentifier);
  } else {
    Reflect.deleteProperty(target, methodName);
    Reflect.defineProperty(target, methodName, {
      get() {
        return container.get(serviceIdentifier);
      },
      set(value) {
        return value;
      },
    });
  }

  return __inject(serviceIdentifier)(target, methodName);
}

export function inject(serviceIdentifier: symbol) {
  return (target: any, methodName: string, indexOrDescriptor?: number | PropertyDescriptor) => {
    if (typeof indexOrDescriptor === 'number' && isParameterDecorator(indexOrDescriptor)) {
      return injectParameterDecorator(target, methodName, indexOrDescriptor, serviceIdentifier);
    }

    return injectPropertyDecorator(
      target,
      methodName,
      indexOrDescriptor as PropertyDescriptor,
      serviceIdentifier,
    );
  };
}

export const Inject = inject;
