import { Action, Module, VuexModule } from 'vuex-module-decorators';
import TYPES from '@/di/types';
import { IPainelBffService } from '@/services/painel-bff.service';
import { Inject } from '@/di/utils';

@Module
export default class FailPanel extends VuexModule {
  @Inject(TYPES.PainelBffService)
  painelBffService!: IPainelBffService

  @Action
  getRealtimeAudienceFailData() {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService.getRealtimeAudienceFailData()
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  getRealtimeSimulcastFailData() {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService.getRealtimeSimulcastFailData()
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  getElasticSearchFailData() {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService.getElasticSearchFailData()
      .finally(() => this.context.dispatch('handleLoading', false));
  }
}
