import RedirectionError from '@/core/errors/RedirectionError';
import UndetectedRedirection from '@/core/errors/UndetectedRedirection';
import { injectable } from 'inversify';

export interface IAuthService {
  generateAuthRedirection(): URL;
  checkSigninRedirectCallback(query: any): Promise<unknown>;
}

@injectable()
export default class AuthService implements IAuthService {
  private readonly host: string;

  private readonly clientID: string;

  private readonly redirectURI: string

  constructor() {
    this.host = process.env.VUE_APP_OPEN_CONNECT_API_HOST!;
    this.clientID = process.env.VUE_APP_OPEN_CONNECT_API_CLIENT_ID!;
    this.redirectURI = process.env.VUE_APP_OPEN_CONNECT_API_CALLBACK_URI!;
  }

  generateAuthRedirection() {
    const url = new URL(this.host);
    url.searchParams.set('scope', 'openid');
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('client_id', this.clientID);
    url.searchParams.set('redirect_uri', this.redirectURI);
    url.searchParams.set('scope', 'openid profile email');

    return url;
  }

  // eslint-disable-next-line class-methods-use-this
  checkSigninRedirectCallback(query: URLSearchParams) {
    return new Promise((resolve, reject) => {
      const testProps = ['session_state', 'code'];

      if (testProps.reduce((i, j) => i && query.get(j) !== null, true)) {
        return resolve({
          code: query.get('code'),
        });
      }

      const testErrorProps = ['error'];

      if (testErrorProps.reduce((i, j) => i && j in query, true)) {
        return reject(new RedirectionError(query.get('error')));
      }

      return reject(new UndetectedRedirection());
    });
  }
}
