

















































// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { CollapseTransition } from 'vue2-transitions';
import Vue from 'vue';
import BaseDropdown from '@/components/BaseDropdown.vue';

export default Vue.extend({
  components: {
    CollapseTransition,
    BaseDropdown,
  },
  computed: {
    routeName() {
      return this.$route.meta.label;
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
    };
  },
  methods: {
    toggleNotificationDropDown() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.activeNotifications = false;
    },
    toggleSidebar() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.showMenu = !this.showMenu;
    },
  },
});

