import Vue from 'vue';

// css assets
import 'material-design-icons/iconfont/material-icons.css';
import '@/assets/sass/black-dashboard.scss';
import '@/assets/css/nucleo-icons.css';
import '@/assets/demo/demo.css';

// @ts-ignore
import VModal from 'vue-js-modal';
import NotificationPlugin from '@/components/NotificationPlugin';
import VueLazyload from 'vue-lazyload';

// @ts-ignore
import vSelect from 'vue-select';

// @ts-ignore
import UUID from 'vue-uuid';
import SearchPlugin from '@/plugins/search-plugin';

// @ts-ignore
Vue.use(UUID);
Vue.component('v-select', vSelect);

Vue.use(VModal, { dialog: true });
Vue.use(NotificationPlugin);
Vue.use(SearchPlugin);
Vue.use(VueLazyload, {
  preLoad: 1,
  attempt: 1,
});
