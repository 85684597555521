import {
  Action, Module, VuexModule,
} from 'vuex-module-decorators';
import { IPainelBffService } from '@/services/painel-bff.service';
import { Inject } from '@/di/utils';
import TYPES from '@/di/types';
import { RealtimeMarket } from '@/data/dto/realtime-market.dto';

@Module
export default class RealtimeMarketsModule extends VuexModule {
  @Inject(TYPES.PainelBffService)
  painelBffService!: IPainelBffService

  @Action
  getRealtimeMarketsConfig() {
    this.context.dispatch('handleLoading', true);
    try {
      return this.painelBffService.getRealtimeMarketsConfig().finally(() => this.context.dispatch('handleLoading', false));
    } catch (error) {
      this.context.dispatch('handleLoading', false);
      throw error;
    }
  }

  @Action
  updateRealtimeMarketsConfig(realtimeMarket: RealtimeMarket) {
    this.context.dispatch('handleLoading', true);
    try {
      return this.painelBffService.updateRealtimeMarketsConfig(realtimeMarket)
        .finally(() => this.context.dispatch('handleLoading', false));
    } catch (error) {
      this.context.dispatch('handleLoading', false);
      throw error;
    }
  }
}
