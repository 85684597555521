/* eslint-disable */
import _Vue from 'vue';
import Notifications from './Notifications.vue';


type VerticalAlign = 'top' | 'bottom'
type HorizontalAlign = 'left'| 'center' | 'right'
type Type = 'info' | 'primary' | 'danger' | 'warning' | 'success'

export interface INotificationOptions{
  message?: string,
  title?: string,
  icon?: string,
  verticalAlign?: VerticalAlign,
  horizontalAlign?: HorizontalAlign,
  type?: Type,
  timeout?: number,
  timestamp?: Date,
  component?: Object | Function,
  showClose?: boolean,
  closeOnClick?: boolean,
  clickHandler?: Function
}

export class Notification{
  state = [] // here the notifications will be added

  settings = {
    overlap: false,
    verticalAlign: 'top',
    horizontalAlign: 'right',
    type: 'info',
    timeout: 5000,
    closeOnClick: true,
    showClose: true
  }

  setOptions(options: any) {
    this.settings = Object.assign(this.settings, options);
  }

  removeNotification(timestamp:any) {
    // @ts-ignore
    const indexToDelete = this.state.findIndex(n => n.timestamp === timestamp);
    if (indexToDelete !== -1) {
      this.state.splice(indexToDelete, 1);
    }
  }

  addNotification(notification: any) {
    if (typeof notification === 'string' || notification instanceof String) {
      notification = { message: notification };
    }
    notification.timestamp = new Date();
    notification.timestamp.setMilliseconds(
      notification.timestamp.getMilliseconds() + this.state.length
    );
    notification = Object.assign({}, this.settings, notification);
    // @ts-ignore
    this.state.push(notification);
  }

  notify(options: INotificationOptions | INotificationOptions[]) {
    if (Array.isArray(options)) {
      options.forEach(notificationInstance => {
        this.addNotification(notificationInstance);
      });
    } else {
      this.addNotification(options);
    }
  }
}

export default function NotificationPlugin<NotificationStore>(Vue: typeof _Vue, options?: NotificationStore):
  void {

  const notification = new Notification()

  Vue.prototype.$notification = notification;
  Vue.component('Notifications', Notifications);
  if (options) {
    notification.setOptions(options);
  }
}


