import {
  Action, Module, VuexModule,
} from 'vuex-module-decorators';
import { IPainelBffService } from '@/services/painel-bff.service';
import { Inject } from '@/di/utils';
import TYPES from '@/di/types';

@Module
export default class RealtimeScheduleModule extends VuexModule {
  @Inject(TYPES.PainelBffService)
  painelBffService!: IPainelBffService

  @Action
  getRealtimeSchedule({ marketName, date }: any) {
    this.context.dispatch('handleLoading', true);
    try {
      return this.painelBffService.getRealtimeSchedule(marketName, date).finally(() => this.context.dispatch('handleLoading', false));
    } catch (error) {
      this.context.dispatch('handleLoading', false);
      throw error;
    }
  }
}
