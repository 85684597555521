





import { Component, Vue } from 'vue-property-decorator';
import { IAuthService } from '@/services/auth.service';
import TYPES from '@/di/types';
import { Inject } from '@/di/utils';

@Component({
  components: {
  },
})
export default class Login extends Vue {
  @Inject(TYPES.AuthService)
  private authService!: IAuthService

  private isErrorAuthUser = false;

  public redirectTologinPage() {
    window.location.href = this.authService.generateAuthRedirection().toString();
  }

  public async validateUser(callbackParams: any) {
    try {
      const { origin } = window.location;
      await this.$store.dispatch('login', callbackParams);
      console.log(origin);
      window.location.href = origin;
    } catch ({ response }) {
      this.isErrorAuthUser = true;
    }
  }

  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.authService.checkSigninRedirectCallback(urlParams)
      .then((obj) => {
        this.validateUser(obj);
      })
      .catch((err) => {
        if (err.name === 'UndetectedRedirection') {
          this.redirectTologinPage();
        } else {
          this.isErrorAuthUser = true;
        }
      });
  }
}

