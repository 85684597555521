import { Container } from 'inversify';
import PainelBffService, { IPainelBffService } from '@/services/painel-bff.service';
import AuthService, { IAuthService } from '@/services/auth.service';
import TYPES from '@/di/types';

const container = new Container({ defaultScope: 'Singleton' });

container.bind<IAuthService>(TYPES.AuthService).to(AuthService);
container.bind<IPainelBffService>(TYPES.PainelBffService).to(PainelBffService);

export default container;
