




































import Vue from 'vue';
import SideBar from '@/components/SidebarPlugin/SideBar.vue';
import AuthorizedRouterLink from '@/components/AuthorizedRouterLink.vue';
import LoadBar from '@/components/LoadBar.vue';
import TopNavbar from './TopNavbar.vue';
import DashboardContent from './Content.vue';

export default Vue.extend({
  components: {
    LoadBar,
    TopNavbar,
    DashboardContent,
    SideBar,
    AuthorizedRouterLink,
  },
});

