export default abstract class Entity<T> {
    protected props: T;

    constructor(props: T) {
      this.props = props;
    }

    public get data(): T {
      return this.props;
    }
}
