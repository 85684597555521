import {
  Action, Module, VuexModule,
} from 'vuex-module-decorators';
import { IPainelBffService } from '@/services/painel-bff.service';
import { Inject } from '@/di/utils';
import TYPES from '@/di/types';
import { ReprocessingDTO } from '@/data/dto/reprocessing.dto';

@Module
export default class KantarETLModule extends VuexModule {
  @Inject(TYPES.PainelBffService)
  painelBffService!: IPainelBffService

  @Action
  addReprocessing(reprocessing: ReprocessingDTO) {
    this.context.dispatch('handleLoading', true);
    try {
      return this.painelBffService.addReprocessing(reprocessing).finally(() => this.context.dispatch('handleLoading', false));
    } catch (error) {
      this.context.dispatch('handleLoading', false);
      throw error;
    }
  }
}
