





















import Vue from 'vue';

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: 'Painel Audiencia',
    },
    backgroundColor: {
      type: String,
      default: 'vue',
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: (value) => {
        const acceptedValues = [
          'primary',
          'info',
          'success',
          'warning',
          'danger',
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      autoClose: this.autoClose,
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      addLink: this.addLink,
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      removeLink: this.removeLink,
    };
  },
  components: {
    // SidebarLink,
  },
  computed: {
    /**
     * Styles to animate the arrow near the current active sidebar link
     * @returns {number}
     */
    arrowMovePx() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return this.linkHeight * this.activeLinkIndex;
    },
    shortTitle() {
      return this.title.split(' ')
        .map((word) => word.charAt(0))
        .join('').toUpperCase();
    },
  },
  data() {
    return {
      linkHeight: 65,
      activeLinkIndex: 0,
      windowWidth: 0,
      isWindows: false,
      hasAutoHeight: false,
      links: [],
    };
  },
  methods: {
    findActiveLink() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.links.forEach((link: any, index: any) => {
        if (link.isActive()) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          this.activeLinkIndex = index;
        }
      });
    },
    addLink(link: any) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const index = this.$slots.links.indexOf(link.$vnode);
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.links.splice(index, 0, link);
    },
    removeLink(link: any) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const index = this.links.indexOf(link);
      if (index > -1) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.links.splice(index, 1);
      }
    },
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.$watch('$route', this.findActiveLink, {
      immediate: true,
    });
  },
});

