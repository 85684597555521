import Vuex from 'vuex';
import UserModule from '@/store/modules/user';
import SystemModule from '@/store/modules/system';
import GroupModule from '@/store/modules/group';
import Vue from 'vue';
import ProgramModule from '@/store/modules/program';
import FailPanel from '@/store/modules/fail-panel';
import MarketModule from '@/store/modules/market';
import KantarETLModule from './modules/kantar-etl';
import RealtimeMarketsModule from './modules/realtime-markets';
import RealtimeScheduleModule from './modules/realtime-schedule';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: UserModule,
    group: GroupModule,
    programs: ProgramModule,
    market: MarketModule,
    system: SystemModule,
    failPanel: FailPanel,
    kantarETL: KantarETLModule,
    realtimeMarkets: RealtimeMarketsModule,
    realtimeSchedule: RealtimeScheduleModule,
  },
});
