import {
  Action, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import { IPainelBffService } from '@/services/painel-bff.service';
import { CredentialsDTO } from '@/data/dto/credentials.dto';
import { SessionDTO } from '@/data/dto/session.dto';
import { UserDTO } from '@/data/dto/user.dto';
import TYPES from '@/di/types';
import { Inject } from '@/di/utils';

@Module
export default class UserModule extends VuexModule {
  @Inject(TYPES.PainelBffService)
  painelBffService!: IPainelBffService

  isLogged = !!window.localStorage.getItem('token')

  current?: UserDTO = undefined

  @Action({ commit: 'loginSuccess' })
  login(credentials: CredentialsDTO) {
    return this.painelBffService.login(credentials);
  }

  @Action
  getAllUsers() {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService.findAllUsers()
      .then((data) => data.users)
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  getOneUser(payload: any) {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService.getOneUser(payload.email)
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  updateUserStatus(payload: any) {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService
      .updateUserStatus(payload.email, payload.active)
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  createUser(payload: any) {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService
      .createUser(payload.email, payload.name, payload.groups)
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  addUserToApplicationGroup(payload: any) {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService
      .addUserToApplicationGroup(payload.email, payload.groups)
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  removeUserFromApplicationGroup(payload: any) {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService
      .removeUserFromApplicationGroup(payload.email, payload.groupUUID)
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  editUser(payload: any) {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService
      .createUser(payload.email, payload.name, payload.groups)
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  deleteUser(payload: any) {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService
      .deleteUser(payload.email)
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  async authorize() {
    return this.painelBffService.authorize()
      .then((user) => {
        this.context.commit('setCurrentUser', user);
      })
      .catch(() => {
        this.context.commit('logout');
      });
  }

  @Mutation
  setCurrentUser(user: UserDTO) {
    this.current = user;
  }

  @Mutation
  loginSuccess(session: SessionDTO) {
    window.localStorage.setItem('token', session.token);
    window.localStorage.setItem('refreshToken', session.refreshToken);
  }

  @Mutation
  logout() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('refreshToken');
  }
}
