import VueRouter, { RouteConfig } from 'vue-router';
import Vue from 'vue';
import DashboardLayout from '@/layouts/dashboard/DashboardLayout.vue';
import store from '../store';

Vue.use(VueRouter);

const FailPanel = () => import(/* webpackChunkName: "Home" */'@/views/FailPanel/FailPanel.vue');
const AuthorizationPanel = () => import(/* webpackChunkName: "AuthorizationPanel" */'@/views/AuthorizationPanel/AuthorizationPanel.vue');
const Login = () => import(/* webpackChunkName: "Login" */'@/views/Login/Login.vue');
const ProgramsUpload = () => import(/* webpackChunkName: "ProgramsUpload" */'@/views/ProgramsUpload/ProgramsUpload.vue');
const KantarReprocessing = () => import(/* webpackChunkName: "KantarReprocessing" */'@/views/KantarReprocessing/CreateReprocessing.vue');
const RealtimeMarket = () => import(/* webpackChunkName: "KantarReprocessing" */'@/views/RealtimeMarket/RealtimeMarket.vue');
const RealtimeSchedule = () => import(/* webpackChunkName: "RealtimeSchedule" */'@/views/RealtimeSchedule/RealtimeSchedule.vue');
const ProgramsList = () => import(/* webpackChunkName: "ProgramsList" */'@/views/ProgramsUpload/ProgramsList.vue');
const ProgramsDetail = () => import(/* webpackChunkName: "ProgramsDetail" */'@/views/ProgramsUpload/ProgramsDetail.vue');
const UserNotAuthorized = () => import(/* webpackChunkName: "UserNotAuthorized" */'@/views/Login/UserNotAuthorized.vue');
const ProgramPosterSearch = () => import(/* webpackChunkName: "SearchProgramPoster" */'@/views/ProgramsUpload/ProgramPosterSearch.vue');

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: DashboardLayout,
    beforeEnter: (to, from, next) => {
      const [boardsRoute] = routes.filter(((value) => value.path === '/board'));

      if (boardsRoute.children !== undefined) {
        const [authorizedRoute] = boardsRoute.children.filter((value) => store
          .state.user.current
          .policies.filter((policy: any) => !!policy[value.meta.requiresPermission]).length > 0);
        next({ path: authorizedRoute.path });
      }
    },
  },
  {
    path: '/board',
    alias: '/',
    component: DashboardLayout,
    children: [
      {
        path: 'fail-panel',
        name: 'fail-panel',
        component: FailPanel,
        meta: {
          label: 'Painel de Falhas',
          requiresPermission: 'DASHBOARD_PERMISSION',
        },
      },
      {
        path: 'control-panel',
        name: 'control-panel',
        component: AuthorizationPanel,
        meta: {
          label: 'Autorização',
          requiresPermission: 'MANAGER_PERMISSION',
        },
      },
      {
        path: 'kantar-reprocessing',
        name: 'kantar-reprocessing',
        component: KantarReprocessing,
        meta: {
          label: 'Reprocessamento',
          requiresPermission: 'KANTAR_REPROCESSING_PERMISSION',
        },
      },
      {
        path: 'realtime-markets',
        name: 'realtime-markets',
        component: RealtimeMarket,
        meta: {
          label: 'Gestão de Praças',
          requiresPermission: 'REALTIME_MARKET_PERMISSION',
        },
      },
      {
        path: 'realtime-schedule',
        name: 'realtime-schedule',
        component: RealtimeSchedule,
        meta: {
          label: 'Grade do Afinação',
          requiresPermission: 'REALTIME_SCHEDULE_PERMISSION',
        },
      },
      {
        path: 'program-upload',
        name: 'program-upload',
        component: ProgramsUpload,
        redirect: 'program-upload/programs',
        meta: {
          label: 'Cadastro de Programas',
          requiresPermission: 'IMAGE_UPLOAD',
        },
        children: [
          {
            path: 'programs',
            name: 'programs',
            component: ProgramsList,
            meta: {
              label: 'Cadastro de Programas',
              requiresPermission: 'IMAGE_UPLOAD',
            },
          },
          {
            path: 'search-poster',
            name: 'search-poster',
            component: ProgramPosterSearch,
            meta: {
              label: 'Imagens de Programas',
              requiresPermission: 'IMAGE_UPLOAD',
            },
          },
          {
            path: 'programs/:key',
            name: 'program-detail',
            component: ProgramsDetail,
            meta: {
              label: 'Cadastro de Programas',
              requiresPermission: 'IMAGE_UPLOAD',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/unauthorized',
    name: 'UserNotAuthorized',
    component: UserNotAuthorized,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  const { isLogged, current } = store.state.user;
  const routeIncludes = (name: string | null | undefined) => {
    if (name) {
      return ['Login', 'UserNotAuthorized'].includes(name);
    }
    return false;
  };

  if (!routeIncludes(to.name) && !isLogged) {
    next({ name: 'Login' });
  } else if (!routeIncludes(to.name) && isLogged && current === undefined) {
    try {
      await store.dispatch('authorize', {
        token: window.localStorage.getItem('token'),
        refreshToken: window.localStorage.getItem('refreshToken'),
      });
      next();
    } catch (e) {
      console.log(e);
      next({ name: 'UserNotAuthorized' });
    }
  } else next();
});

export default router;
