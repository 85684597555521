import {
  Module, MutationAction, VuexModule,
} from 'vuex-module-decorators';

@Module
export default class SystemModule extends VuexModule {
  isLoading = false

  sidebar = {
    in: null,
    out: null,
  }

  @MutationAction
  async handleSidebar(status: boolean) {
    const sidebar = {
      in: status || null,
      out: !status || null,
    };

    return { sidebar };
  }

  @MutationAction
  async handleLoading(status: boolean) {
    const isLoading = status;
    return { isLoading };
  }
}
