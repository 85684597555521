import { Action, Module, VuexModule } from 'vuex-module-decorators';
import TYPES from '@/di/types';
import { IPainelBffService } from '@/services/painel-bff.service';
import { Inject } from '@/di/utils';

type CreateGroupPayload = { name: string; permissions: { key: string; value: string }[] }
type UpdateGroupPayload = {
  uuid: string; name: string; permissions: { key: string; value: string }[];
}
type UpdateGroupStatus = { uuid: string; active: boolean }

@Module
export default class GroupModule extends VuexModule {
  @Inject(TYPES.PainelBffService)
  painelBffService!: IPainelBffService

  @Action
  getAllGroups() {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService.findAllGroups()
      .then((data) => data.groups)
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  getOneGroup(payload: { uuid: string }) {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService.getOneGroup(payload.uuid)
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  createGroup(payload: CreateGroupPayload) {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService
      .createGroup(payload.name, payload.permissions)
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  updateGroup(payload: UpdateGroupPayload) {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService
      .updateGroup(payload.uuid, payload.name, payload.permissions)
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  updateGroupStatus(payload: UpdateGroupStatus) {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService
      .updateGroupStatus(payload.uuid, payload.active)
      .finally(() => this.context.dispatch('handleLoading', false));
  }

  @Action
  removeGroup(payload: UpdateGroupStatus) {
    this.context.dispatch('handleLoading', true);
    return this.painelBffService
      .deleteGroup(payload.uuid)
      .finally(() => this.context.dispatch('handleLoading', false));
  }
}
