








import { Component, Vue } from 'vue-property-decorator';
import Login from '@/views/Login/Login.vue';
import Notifications from '@/components/NotificationPlugin/Notifications.vue';

@Component({
  components: {
    Login,
    Notifications,
  },
})
export default class App extends Vue {}
