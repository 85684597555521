import axios from 'axios';

export default class UploadImageService {
  uploadImage(url: string, fileType: string, file: Buffer) {
    return axios.put(url, file, {
      headers: {
        'x-amz-acl': 'public-read',
        'Content-Type': fileType,
        'Content-Encoding': 'base64',
      },
    });
  }
}
