



























import Vue from 'vue';

export default Vue.extend({
  name: 'base-dropdown',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    titleTag: {
      type: String,
      default: 'button',
    },
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    titleClasses: {
      type: [String, Object, Array],
    },
    menuClasses: {
      type: [String, Object],
    },
    menuOnRight: {
      type: Boolean,
    },
    ariaLabel: String,
  },
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        // eslint-disable-next-line no-param-reassign
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        el.clickOutsideEvent = function (event: any) {
          // here I check that click was outside the el and his childrens
          // eslint-disable-next-line eqeqeq
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            vnode.context[binding.expression](event);
          }
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
      this.$emit('change', this.isOpen);
    },
    closeDropDown() {
      this.isOpen = false;
      this.$emit('change', false);
    },
  },
});
