import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { injectable, unmanaged } from 'inversify';

declare module 'axios' {
  export interface AxiosInstance {
    request<T = any>(config: AxiosRequestConfig): Promise<T>;

    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;

    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;

    head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;

    post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;

    put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;

    patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  }
}

@injectable()
export abstract class HttpClient {
  protected readonly client: AxiosInstance;

  protected constructor(@unmanaged() baseURL: string, @unmanaged() headers?: any) {
    this.client = axios.create({
      baseURL,
      headers,
    });

    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.client.interceptors.response.use(
      this.handleResponse,
      this.handleError,
    );

    this.client.interceptors.request.use(
      this.handleRequests,
      this.handleError,
    );
  }

  private handleRequests = (request: AxiosRequestConfig) => request

  private handleResponse = ({ data }: AxiosResponse) => data;

  protected handleError = (error: any) => Promise.reject(error)
}
