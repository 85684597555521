// @ts-ignore
import { uuid } from 'vue-uuid';
import Entity from '@/data/entity';
import moment from 'moment';

export interface IProgramProps {
  id: string;
  program: string;
  title: string;
  url: string;
  tvNetworkId: string;
  created_at: number;
  marketId: string;
  alternativeName: string;
}

export class Program extends Entity<IProgramProps> {
  constructor(
    title: string,
    program: string,
    url: string,
    marketId: string,
    alternativeName: string,
  ) {
    super({
      title,
      // eslint-disable-next-line @typescript-eslint/camelcase
      created_at: Program.generateCreatedDate(),
      id: Program.generateUUID(),
      marketId,
      alternativeName,
      program,
      tvNetworkId: 'Globo',
      url,
    });
  }

  get id(): string {
    return this.props.id;
  }

  get program(): string {
    return this.props.program;
  }

  get title(): string {
    return this.props.title;
  }

  get url(): string {
    return this.props.url;
  }

  get tvNetworkId(): string {
    return this.props.tvNetworkId;
  }

  get createdAt(): number {
    return this.props.created_at;
  }

  get marketId(): string {
    return this.props.marketId;
  }

  get alternativeName(): string {
    return this.props.alternativeName;
  }

  static generateUUID() {
    return uuid.v4();
  }

  static generateCreatedDate() {
    return parseInt(moment().format('YYYY'), 10);
  }
}
