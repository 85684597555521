import 'reflect-metadata';
import './plugins';
import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';

import 'bootstrap-vue/dist/bootstrap-vue.css';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

Vue.config.productionTip = false;

class AppBootstrap {
  constructor() {
    AppBootstrap.loadVueApp();
  }

  private static loadVueApp(): void {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }
}

// eslint-disable-next-line no-new
new AppBootstrap();
