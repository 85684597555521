/* eslint-disable */
import { HttpClient } from '@/core/http-client';
import { CredentialsDTO } from '@/data/dto/credentials.dto';
import { SessionDTO } from '@/data/dto/session.dto';
import { UserDTO } from '@/data/dto/user.dto';
import { GroupDTO } from '@/data/dto/group.dto';
import {ProgramDTO} from "@/data/dto/program.dto";
import {Program} from "@/data/entity/program.entity";
import ProgramsMapper from "@/data/mapper/program.mapper";
import {injectable} from "inversify";
import {SignedUrlDto} from "@/data/dto/signed-url.dto";
import {RealtimeServiceStatus} from "@/data/dto/realtime-service-status";
import { MarketDTO } from '@/data/dto/market.dto';
import { ReprocessingDTO } from '@/data/dto/reprocessing.dto';
import { RealtimeMarket } from '@/data/dto/realtime-market.dto';
import { ProgramPosterSearchRequestDTO } from '@/data/dto/program.dto';
import { RealtimeExhibitionDTO } from '@/data/dto/realtime-exhibition.dto';
type UsersResponse = {users: UserDTO[]}
type GroupsResponse = {groups: GroupDTO[]}
type Permissions = {key: string; value: string}

/**
 * Interface for SysAdmBff
 */
export interface IPainelBffService {
  login(credentials: CredentialsDTO): Promise<SessionDTO>;
  findAllApplicationGroups(): Promise<any>;
  authorize(): Promise<UserDTO>;
  findAllUsers(): Promise<UsersResponse>;
  findAllGroups(): Promise<GroupsResponse>;
  createUser(email: string, name: string, groups: string[]): Promise<void>;
  updateUserStatus(email: string, active: boolean): Promise<void>;
  deleteUser(email: string): Promise<void>;
  createGroup(name: string, permissions: Permissions[]): Promise<void>;
  updateGroupStatus(uuid: string, status: boolean): Promise<void>;
  deleteGroup(uuid: string): Promise<void>;
  getOneGroup(uuid: string): Promise<GroupDTO>;
  getOneUser(email: string): Promise<GroupsResponse>;
  addUserToApplicationGroup(email: string, groups: string[]): Promise<void>;
  removeUserFromApplicationGroup(email: string, groupUUID: string): Promise<void>;
  updateGroup(uuid: string, name: string, permissions: Permissions[]): Promise<void>;
  getAllPrograms(): Promise<ProgramDTO[]>;
  getOneProgram(key: string): Promise<Program[]>;
  getAllMarketsNames (): Promise<MarketDTO[]>;
  removeImage(key: string): Promise<void>;
  addImage(program: Program): Promise<void>;
  getUploadImageSignedUrl(name: string, contentType:string): Promise<SignedUrlDto>
  addReprocessing(data : ReprocessingDTO) :  Promise<void>;
  getRealtimeMarketsConfig(): Promise<RealtimeMarket[]>;
  updateRealtimeMarketsConfig(realtimeMarketConfig: RealtimeMarket): Promise<void>;
  getRealtimeAudienceFailData(): Promise<RealtimeServiceStatus[]>;
  getRealtimeSimulcastFailData(): Promise<RealtimeServiceStatus[]>;
  getRealtimeSchedule(marketName: string, date: string): Promise<RealtimeExhibitionDTO[]>;
  getElasticSearchFailData() : Promise<any>;
  searchPoster(searchTerm: string, currentPage: number, perPage: number) : Promise<ProgramPosterSearchRequestDTO[]>;
}

@injectable()
export default class PainelBffService extends HttpClient implements IPainelBffService {
  constructor() {
    super(process.env.VUE_APP_SYSASDM_BFF_HOST!, {
      token: window.localStorage.getItem('token'),
    });
  }
  // region Users
  public login = (credentials: CredentialsDTO) => this.client
    .post<SessionDTO>('/v1/users/current/auth',
      {
        code: credentials.code,
      })

  public authorize = () => this.client.get<UserDTO>('/v1/users/current/auth')

  public findAllUsers = () => this.client.get<UsersResponse>('/v1/users/');

  public getOneUser = (email: string) => this.client.get<GroupsResponse>(`/v1/users/${email}`)

  public createUser = (email: string, name: string, groups: string[]) => this.client.post<void>('/v1/users', {
    email,
    name,
    groups,
  })

  public updateUserStatus = (email: string, active: boolean) => this.client.put<void>('/v1/users', {
    email,
    active,
  })

  public addUserToApplicationGroup = (email: string, groups: string[]) => this.client.put<void>(`/v1/users/${email}`, {
    groups,
  })

  public deleteUser = (email: string) => this.client.delete<void>('/v1/users', {
    data: {
      email,
    },
  })

  public removeUserFromApplicationGroup = (email: string, groupUUID: string) => this.client.delete<void>(`/v1/users/${email}`, {
    data: {
      groupUUID,
    },
  })

  // endregion

  // region Groups
  public findAllApplicationGroups = () => this.client.get<any>('/v1/groups')

  public findAllGroups = () => this.client.get<GroupsResponse>('/v1/groups/');

  public updateGroupStatus = (uuid: string, status: boolean) => this.client.put<void>('/v1/groups/', {
    uuid,
    status,
  })

  public createGroup = (name: string, permissions: Permissions[]) => this.client.post<void>('/v1/groups/', {
    name,
    permissions,
  })

  public updateGroup = (uuid: string, name: string, permissions: Permissions[]) => this.client.put<void>(`/v1/groups/${uuid}`, {
    name,
    permissions,
  })

  public deleteGroup = (uuid: string) => this.client.delete<void>(`/v1/groups/${uuid}`)

  public getOneGroup = (uuid: string) => this.client.get<GroupDTO>(`/v1/groups/${uuid}`)
  // endregion

  // region Programs
  public getAllPrograms = () => this.client.get<Program[]>('/v1/programs').then( data => new ProgramsMapper().toDTO(data))

  public searchPoster = (searchTerm: string, currentPage: number, perPage: number) => this.client.get<ProgramPosterSearchRequestDTO[]>(`v1/programs/search-poster?searchTerm=${searchTerm}&currentPage=${currentPage}&perPage=${perPage}`);

  public getOneProgram = (key: string) => this.client.get<Program[]>(`/v1/programs/${key}`)

  public removeImage = (key: string) => this.client.delete<void>(`/v1/programs/${key}`)

  public addImage = (program: Program) => this.client.post<void>('/v1/programs/', program.data)

  public getUploadImageSignedUrl = (name: string, contentType: string) => this.client.post<SignedUrlDto>('/v1/programs/image', {name, contentType})
 //endregion

  // region market
  public getAllMarketsNames = () => this.client.get<MarketDTO[]>('/v1/markets/')
  //endregion

  // region FailPanel
  public getRealtimeAudienceFailData = () => this.client.get<RealtimeServiceStatus[]>('/v1/alerts/audience/realtime')
  public getRealtimeSimulcastFailData = () => this.client.get<RealtimeServiceStatus[]>('/v1/alerts/audience/simulcast')
  public getElasticSearchFailData = () => this.client.get<any>('/v1/alerts/services/elasticsearch')
  //endregion

  // region kantar-etl
  public addReprocessing = (data : ReprocessingDTO) => this.client.post<void>('/v1/kantar-etl/reprocessing', data)
  //endregion
  
 // region realtime-markets 
 public getRealtimeMarketsConfig = () => this.client.get<RealtimeMarket[]>('/v1/realtime-markets/realtime-markets-config');
 public updateRealtimeMarketsConfig = (realtimeMarketConfig: RealtimeMarket) => this.client.put<void>('/v1/realtime-markets/update-realtime-markets-config', realtimeMarketConfig);
 // endregion

 // region realtime-schedule
 public getRealtimeSchedule = (marketName: string, date: string) => this.client.get<RealtimeExhibitionDTO[]>(`/v1/realtime-schedule?marketName=${marketName}&date=${date}`);
 // endregion
}